import React, {Component} from 'react'
import Link from 'gatsby-link'
import staticdata from '../../staticdata.json'
import styled from 'styled-components'
import Fade from 'react-reveal/Fade'
import { Controller, Scene } from 'react-scrollmagic'

import Circle from '../components/circle.js'
import HeadPage from '../components/headPage'
import CardProject from '../components/cardProject'
import TileProject from '../components/tileProject'
import Cell from '../components/cell'
import HeroImage from '../components/heroImage'
import BackgroundText from '../components/backgroundText.js'

import sevenhugsHeroReveal from '../images/sevenhugs-thumb-gif3.gif'
import musicThumbnail from '../images/petit-bain-5.png'
import photoThumbnail from '../images/Photo1.png'
import soundThumbnail from '../images/sound-thumbnail.png'
import motionThumbnail from '../images/Trapcode Hero Particles 1.gif'
import triberMotion from '../images/triberMotion.gif'
import agorizeThumbnail from '../images/agorize-thumbnail.png'
import seCollab3 from '../images/SE-Collab-3.png'
import banner01 from '../images/banner01.png'
import granularThumbnail from '../images/granular-thumbnail.png';

import Layout from "../components/layout"

class SideProjects extends Component {

	constructor(props) {
		super(props);
	}

	render = () => {
	return (
	<Layout>
		<div> 
		<div className="CardsList">
		<Fade bottom cascade delay={150}duration={600} duration={550} distance="1.6em" ssrFadeout>
			
			<Link to="/motion">
				<CardProject 
					caption="3D & Motion"
					title="Motion Experiments"
					image={motionThumbnail}
					fakelink="See project"
					

				/>
			</Link>

			<Link to="/music">
				<CardProject 
					caption="20 pixels and Montreuil Zoo"
					title="10 years of music production"
					image={musicThumbnail}
					fakelink="See project"
					

				/>
			</Link>

			<Link to="/photos">
				<CardProject 
					caption="Photography"
					title="Landscape and street photography"
					image={photoThumbnail}
					fakelink="See project"
					

				/>
			</Link>
		</Fade>
		</div>
		

		
		
		<div className="Footer">
			<div className="Left">
				<p>
					Interested in working together?<br />
					<a href="mailto:alexandretrimoulet@gmail.com">Get in touch →</a>.
				</p>
			</div>
			<div className="Right">
				<p className="SocialLinks">
					Find me on
					<a id="linkedin" href="https://www.linkedin.com/in/alexandretrimoulet" target="_blank"> LinkedIn</a>, 
					<a id="medium" href="https://medium.com/@AlexTrimoulet" target="_blank"> Medium</a>, 
					<a id="twitter" href="https://twitter.com/AlexTrimoulet" target="_blank"> Twitter</a>,
					<a id="soundcloud" href="https://soundcloud.com/osphoz/" target="_blank"> SoundCloud</a>,
					<a id="dribbble" href="https://dribbble.com/alexandret" target="_blank"> Dribbble</a>, 
					<a id="producthunt" href="https://www.producthunt.com/@alextrimoulet" target="_blank"> Product Hunt</a>,  & 
					<a id="instagram" href="https://instagram.com/alextrmlt" target="_blank"> Instagram</a>.
				</p>
			</div>
			<div className="developedBy">
				Portfolio designed & developed in React by Alex Trimoulet 💪🏻 <br />
				© All rights reserved 2022
			</div>
		</div>


	</div>
	</Layout>
)
}
}

export default SideProjects

